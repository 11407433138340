.App-home-intro-description {
    padding: 38px 0px;
}

.App-home-intro-description-image-container {
    margin: 30px -75px;
}

.App-home-intro-description-image {
    width: 100%;
}

@media screen and (min-width: 655px) {
    .App-home-intro-description-image-container{
        display: none;
    }

    .App-home-intro-description-detailed{
        display: none;
    }
}