.BookishContainer {
    position: relative;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.BookishContainer-top-left {
    position: absolute;
    left: 0;
    top: 0;
    height: 50%;
    width: 50%;
    background-color: transparent;
}

.BookishContainer-top-right {
    position: absolute;
    right: 0;
    top: 0;
    height: 50%;
    width: 50%;
    background-color: transparent;
}

.BookishContainer-bottom-left {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50%;
    width: 50%;
    background-color: transparent;
}

.BookishContainer-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 50%;
    width: 50%;
    background-color: transparent;
}

.BookishContainer-inner {
    position: relative;
    padding: 30px 30px 30px 30px;
}

.BookishContainer-inner .escape {
    margin-top: -30px;
    margin-left: -30px;
    margin-right: -30px;
}

.BookishContainer-inner .escape-left {
    margin-left: -30px;
}

.BookishContainer-inner .escape-right {
    margin-right: -30px;
}

.BookishContainer-inner .escape-top {
    margin-top: -30px;
}

.BookishContainer-header {
    text-transform: uppercase;
    font-size: 42pt;
    padding: 38px 0px 25px 0px;
}

.BookishContainer-header-images {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
}

.BookishContainer-subheader {
    font-size: 28pt;
    padding: 38px 0px 25px 0px;
}

.BookishContainer-header + .BookishContainer-subheader {
    margin-top: -35px;
    padding: 0px 0px 25px 0px;
}

.BookishContainer-header span, .BookishContainer-subheader span {
    display: inline;
}

.BookishContainer-header span.desktop, .BookishContainer-subheader span.desktop {
    display: none;
}

@media screen and (min-width: 1140px) {
    .BookishContainer-inner {
        padding: 30px 75px 30px 75px;
    }

    .BookishContainer-inner .escape {
        margin-top: -30px;
        margin-left: -75px;
        margin-right: -75px;
    }
    
    .BookishContainer-inner .escape-left {
        margin-left: -75px;
    }
    
    .BookishContainer-inner .escape-right {
        margin-right: -75px;
    }
    
    .BookishContainer-inner .escape-top {
        margin-top: -30px;
    }

    .BookishContainer-header {
        display: inline-block;
    }
    
    .BookishContainer-subheader {
        display: inline-block;
    }

    .BookishContainer-header + .BookishContainer-subheader {
        padding-left: 15px;
    }

    .BookishContainer-header.has-desktop span, .BookishContainer-subheader.has-desktop span {
        display: none;
    }
    
    .BookishContainer-header.has-desktop span.desktop, .BookishContainer-subheader.has-desktop span.desktop {
        display: inline;
    }
}