.App-home-project-driver-image-container {
    margin: 25px -30px;
}

.App-home-project-driver-image-container .international-driver {
    width: 100%;
}

.App-home-project-btn-container{
    margin: 25px 0px 38px 0px;
}

.App-home-project-subtitle {
    font-family: 'eurostile-condensed', 'Assistant', sans-serif;
    font-weight: 850;
    font-size: 40px;
    line-height: 48px;
}

@media screen and (min-width: 1140px) {
    .App-home-project-driver-image-container {
        margin: 25px -75px;
    }
}