.BorderButton {
    display: inline-block;
    color: #FFDD00;
    border: 2px solid #FFDD00;
    box-sizing: border-box;
    border-radius: 0px;
    font-size: 18pt;
    line-height: 1.5;
    padding: 3px 22px 6px 22px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    letter-spacing: 4px;
}

.BorderButton:hover {
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.BorderButton:active {
    transform: scale(0.95);
}