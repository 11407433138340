.App-home-heindia-image-container {
    margin: 25px -30px;
}

.App-home-heindia-image-container-meeting {
    width: 100%;
}

.App-home-heindia-actions {
    margin: 25px 0px 38px 0px;
    text-align: center;
}

@media screen and (min-width: 1140px) {
    .App-home-heindia-image-container {
        margin: 25px -75px;
    }
}