.ToggleSwitch {
    position: relative;
    font-size: 0;
}

.ToggleSwitch-value {
    display: inline-block;
    vertical-align: top;
    font-size: 16pt;
    height: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    color: #95A6B1;
}

.ToggleSwitch-value.checked {
    color: #000000;
}

.ToggleSwitch-inner {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0px 8px 0px 10px;
    cursor: pointer;
}

.ToggleSwitch-input {
    height: 30px;
    width: 66px;
    opacity: 0;
    cursor: pointer;
}

.ToggleSwitch-input + label::before {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 10px;
    height: 18px;
    width: 45px;
    border: 1px solid #95A6B1;
    border-radius: 9px;
    background: #95A6B1;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
}
  
.ToggleSwitch-input + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 30px;
    width: 30px;
    border: 1px solid #18375E;   
    border-radius: 50%;
    background: #18375E;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s ease-in;
}
  
.ToggleSwitch-input:checked + label::after {
    left: 35px; 
    transition: all 0.3s ease-in;
}