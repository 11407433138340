.App-footer {
    position: relative;
    background-color: #18375E;
    color: #FFFFFF;
    display: block;
}
  
.App-footer-logo {
    display: inline-block;
}
  
.App-footer-logo img {
    height: 48px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
  
.App-footer-logo span {
    display: block;
    font-size: 18pt;
    margin-top: 9px;
}

.App-footer-email {
    margin: 18px 0px 0px 0px;
}

.App-footer-email img {
    margin: 0px 10px 0px 0px;
}

.App-footer-phone {

}

.App-footer-phone img {
    margin: 0px 11px 0px 3px;
}

.App-footer-email img, .App-footer-phone img {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.APP-footer-vle-phone,
.APP-footer-vle-email {
    font-family: 'Assistant';
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #FFDD00;
}

.App-footer-address, .App-footer-contact {
    margin: 18px 0px;
}

.App-footer-address span {
    display: block;
}

.App-footer-follow-us {
    display: none;
    position: absolute;
    right: 0;
    top: 207px;
}

.App-footer-follow-us-text, .App-footer-follow-us-linkedin {
    display: inline-block;
    vertical-align: middle;
}

.App-footer-follow-us-text {
    margin: 0px 20px 3px 0px;
}

.App-footer-follow-us a img {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-footer-follow-us a img.normal {
    display: block;
}

.App-footer-follow-us a img.hover {
    display: none;
}

.App-footer-follow-us a:hover img.normal {
    display: none;
}

.App-footer-follow-us a:hover img.hover {
    display: block;
}

.App-footer-maps {
    padding: 0px 0px 8px 0px;
}

.App-footer-maps-privacy {
    position: relative;
    width: 100%;
    height: 320px;
    margin: 0px 0px 7px 0px;
    overflow: hidden;
}

.App-footer-maps-privacy-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-footer-maps-privacy img {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
}

.App-footer-maps-privacy img.background {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.App-footer-maps-privacy img.icon {
    width: 40px;
    margin: 65px 0px 5px 0px;
}

.App-footer-maps-privacy img.info {
    width: 200px;
}

.App-footer-maps-privacy a {
    position: absolute;
    top: 184px;
    left: 0;
    right: 0;
    margin: 0px auto 0px auto;
    width: 200px;
    height: 25px;
    background-color: transparent;
}

.App-footer-actions {
    margin-top: 18px;
    height: 90px;
    text-align: center;
}

.App-footer-actions .BorderButton-datenschutz {    
    float:left;
}

.App-footer-actions .BorderButton-impressum {
    float:right;
}

.App-footer-react {
    position: relative;
    font-size: 10pt;
    text-align: right;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #858585;
}

.App-footer-react-inner {
    display: inline-block;
}

.App-footer-react a {
    color: #858585;
}

.App-footer-react-lottie {
    display: none;
    position: absolute;
    right: 25px;
    bottom: 13px;
    width: 50px;
}

.App-footer-react-inner:hover .App-footer-react-lottie {
    display: block;
}

.App-footer.downsize .App-footer-address, .App-footer.downsize .App-footer-react, .App-footer.downsize .App-footer-contact {
    display: none;
}

.App-footer.downsize .App-footer-actions {
    margin: 28px 0px 0px 0px;
    height: 50px;
}

@media screen and (max-width: 654px) {
    .App-footer-actions .BorderButton {
        width: 48%;
    }

    .App-footer-actions .BorderButton-datenschutz {
        margin-right: 2%;
    }
}

@media screen and (min-width: 655px) {
    .App-footer.downsize {
        min-height: 0px;
    }

    .App-footer-actions .BorderButton {
        float: none;
        display: inline-block;
     }

     .App-footer-actions .BorderButton-datenschutz {
        margin-right: 48px;
    }
}

@media screen and (min-width: 1140px) {
    .App-footer-follow-us {
        display: block;
    }

    .App-footer.downsize {
        padding-top: 12px;
    }
    
    .App-footer.downsize .App-footer-follow-us {
        top: 125px;
    }

    .App-footer.downsize .App-footer-actions {
        margin: 45px 0px 0px 0px;
        height: 60px;
    }
}