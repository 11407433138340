.App-project-subtitle {
    font-family: 'eurostile-condensed', sans-serif;
    font-weight: 850;
    font-size: 40px;
    line-height: 48px;
}

.App-project-image-container, .App-project-video-container {
    margin: 25px -30px;
}

.App-project-image-container .project-image {
    width: 100%;
}

.App-project-video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.App-project-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.App-project .section-start {
    padding-top: 38px;
}

.App-project .section-end {
    padding-bottom: 38px;
}

.App-project .TextSection-title {
    font-family: 'eurostile-condensed', sans-serif;
    font-weight: 850;
    font-size: 28px;
    line-height: 34px;
}

.App-project .TextSection-content {
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
}

.App-project-more-drivers-locations .BookishContainer{
    margin: 30px 0px;
    word-break: break-word;
}

.App-project-more-drivers-locations .BookishContainer-header-images img {
    width: 150px;
}

.App-project-more-drivers-locations .BookishContainer-header {
    font-size: 40px;
    line-height: 48px;
    font-weight: 850;
    width: 100%;
    text-align: center;
    padding: 25px 0;
}

.App-project-more-drivers-locations .BookishContainer-inner {
    padding: 68px 30px;
    height: 100%;
}

.App-projects-intro img {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}


@media screen and (min-width: 1140px) {

    .App-project-image-container, .App-project-video-container {
        margin: 25px -75px;
    }
    
    .App-project-more-drivers-locations {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .App-project-more-drivers-locations .BookishContainer {
        width: 310px;
    }

    .App-project-more-drivers-locations .BookishContainer-header-images img{
        width: 120px;
    }

    .App-project-more-drivers-locations .BookishContainer-inner{
        min-height: 730px;
    }
}