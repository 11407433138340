@keyframes appHomeReferencesTabsAnimation {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(15px);
    }
    100% {
        transform: translateX(0);
    }
}

.App-home-references {
    margin: 0 -30px;
    padding-bottom: 38px;
    overflow: hidden;
}

.App-home-references-tabs-container {
    margin: 45px 0px 0px 0px;
}

.App-home-references-tabs {
    display: flex;
    scrollbar-width: none;
    cursor: grab;
}

.App-home-references-tabs::-webkit-scrollbar {
    display: none;
}

.App-home-references-tabs.animation {
    animation: appHomeReferencesTabsAnimation ease 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.App-home-references-tab-item {
    max-width: 170px;
    min-width: 170px;
    max-height: 115px;
    min-height: 115px;
    margin-right: 15px;
    padding: 8px;
    background-color: #FFFFFF;
    filter: grayscale(100%);
    overflow: hidden;
    display: inline-block;
}

.App-home-references-tab-item:hover {
    filter: none;
}

.App-home-references-tab-item:last-child {
    margin-right: 0px;
}

.App-home-references-tab-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-home-references-text {
    margin: 0 30px;
}

@media screen and (min-width: 1140px) {
    .App-home-references {
        margin-left: -75px;
        margin-right: -75px;
    }

    .App-home-references-tab-item {
        margin-right: 10px;
    }

    .App-home-references-tab-item:last-child {
        margin-right: 0px;
    }

    .App-home-references-text {
        margin-left: 75px;
        margin-right: 75px;
    }
}